import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Breadcrumb, Image, Figure } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import LuteinComplexMobile from "./../../images/hepato-zasilvat-organ-header.jpg";
import LuteinComplexTablet from "./../../images/hepato-zasilvat-organ-header.jpg";
import LuteinComplexDesktop from "./../../images/hepato-zasilvat-organ-header.jpg";
import LuteinComplexXL from "./../../images/hepato-zasilvat-organ-header.jpg";

import vacuum from "./../../images/hepato-zasilvat-dirty-filter.jpg";
import stoinov from "./../../images/hepato-zasilvat-dr-stoinov.jpg";
import hepatofelin from "./../../images/hepatofelin-article-box.jpg";

const HepatofelinZasilvat = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepatofelin-zasilvat" }}>
    <Seo title="Засилват орган, който ни пази" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ЗАСИЛВАТ ОРГАН, КОЙТО НИ ПАЗИ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Засилват орган, който ни пази"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Засилват орган, който ни пази
          </h1>
          <h2 className="font-light">
            <em>Прочистваме „филтъра“ ни против отрови и вируси</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Органът, притежаващ силата да ни пази от отрови и вируси, не боли и
            не се усеща, макар и да е в лошо състояние. Задръстен и изнемощял,
            неспособен да ни осигури чиста кръв, той незабелязано съсипва
            здравето ни, докато накрая не започнат да се обаждат необясними
            проблеми.
          </p>
          <p></p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h3>Имаме ли шанс да сме добре?</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Сменяме торбичката с мръсотия на прахосмукачката редовно, но рядко
            се сещаме да почистим „филтъра“ на собственото ни тяло – черния
            дроб. Как тогава да очакваме тялото ни да работи? Отговорен за
            толкова много белѝ, този важен орган трябва регулярно да се
            „почиства“. Но без химия, ако не искаме допълнително да утежним
            положението.
          </p>
          <p>
            На това условие обаче отговарят не много продукти. Сред тях се
            открояват като работещи само тези, които наистина покриват високите
            изисквания на специалистите. Такъв е природният еликсир за черен
            дроб, известен у нас като ХЕПАТОФЕЛИН (Hepatofelin). По два начина
            той експертно се справя с утежняването на този важен орган:
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>Изчистване и възстановяване</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col md={4} xs={12}>
          <Figure className="ml-3 mb-3 w-100 float-right ">
            <Figure.Image
              alt="Както почистваме филтъра на прахосмукачката, така ХЕПАТОФЕЛИН
              действа на черния ни дроб."
              src={vacuum}
            />
            <Figure.Caption>
              Както почистваме филтъра на прахосмукачката, така ХЕПАТОФЕЛИН
              действа на черния ни дроб.
            </Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <p>
            ХЕПАТОФЕЛИН осигурява нужната стимулация черният дроб да ни служи
            отново, благодарение на петте си ценни природни съставки:
            концентрирани дози силимарин (бял трън), артишок, глухарче,
            източници на Омега мастни киселини – ленено масло и пречистено соево
            масло. По този начин, задействани да работят правилно, чернодробните
            клетки започват процес на самовъзстановяване. Когато те най-накрая
            са в добра форма, можем да разчитаме, че ще си вършат работата – да
            ни предпазват от вредното действие на лошата храна, алкохолът,
            цигарите или някои вируси и паразити.
          </p>
          <p>
            Скоро това дава отражение върху цялостното ни здраве – усещането за
            тежест в дясно отминава, а преносът на по-чиста кръв съживява орган
            по орган – необясними проблеми могат да изчезнат, така както са се
            появили. Подобреното храносмилане е друг ползотворен ефект на
            ХЕПАТОФЕЛИН, за който потърпевшите споделят.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>Усилване на ефекта</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            За да усилят ефекта на ценните съставки, експертният екип, създал <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link>, стига до важен извод –
            формата е ключова за усвояването. Разбират, че ако е на таблетка, за
            тялото ще е по-трудно да „изсмуче“ нужните вещества, ако пък е
            течно, много бързо може да ги изхвърли чрез урината. Затова
            ХЕПАТОФЕЛИН е с уникална патентована формула – активните му съставки
            са предварително разтворени в масло и херметически затворени в
            специална капсула. Така организмът много по-лесно „си взима“ нужното
            и ефективността на съставките се усилва още повече.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>Удобен прием</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Два пъти на ден след хранене - лесно и просто. Това съветват
            гастроентеролозите. Ефектът от прием в продължение на поне три
            месеца се отразява директно на функцията на чернодробните клетки.
            Метаболизмът на мазнините чувствително се повлиява, а като бонус -
            дори и нивата на серумния холестерол. Разработка от ранга на
            ХЕПАТОФЕЛИН е предпочитана от много наши опитни специалисти.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h3>Как да предпазим черния дроб?</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link> може да бъде използван
            както за профилактика при очаквано чернодробно увреждане (лекарства,
            детокс), така и при облекчаване на вече налични болестни процеси,
            т.е. е подходящ както за пациенти в риск, така и за такива с
            установено заболяване. Приема се като протекция при употребата на
            различни хепатотоксични медикаменти, включително антибиотици и
            противогъбични препарати, при хронична алкохолна употреба, при
            нерационално хранене, както и при редица по-сериозни усложнения.
            Практиката ми показва, че когато черният дроб е засегнат и се налага
            конвенционално лечение, подкрепата в лицето на ХЕПАТОФЕЛИН се явява
            един от най-надеждните ми „партньори“.
          </p>
          <p>
            <em>
              Д-р Николай Стойнов, специалист Гастроентерлог в УМБАЛ „Пълмед“,
              Пловдив.
            </em>
          </p>
        </Col>
        <Col sm={3} xs={5}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="д-р Николай Стойнов, специалист Гастроентерлог в УМБАЛ „ Пълмед“, Пловдив."
              src={stoinov}
            />
            <Figure.Caption>
              д-р Николай Стойнов, специалист Гастроентерлог в УМБАЛ „Пълмед“,
              Пловдив.
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>

      <Row className="product-container mb-4">
        <Col>
          <Link to="/hepatofelin">
            <Image className="w-100" src={hepatofelin} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h4>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h4>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default HepatofelinZasilvat;
